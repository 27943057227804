@import url("https://fonts.googleapis.com/css?family=Saira+Semi+Condensed&display=swap");
* {
  font-family: "Saira Semi Condensed", sans-serif;
  transition: all 0.5s ease;
}





.field:nth-of-type(2) {
  margin: 16px 0;
}

.card .card-body {
  padding: 0.75rem 0.5625rem !important;
}





