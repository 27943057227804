
.headerContainer, .leftContainer {
    background-color: #fff;
    max-width: 480px;
    width: 100%;

}
html{
    scroll-behavior: smooth !important;
}
* {
    font-family: "Roboto";
}

*, :after, :before {
    box-sizing: border-box;
}

.leftContainer {
    min-height: 0%;
    position: relative;
}

.headerContainer {
    position: fixed;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
    height: 0px;
    padding: 0;
    top: 0;
    z-index: 2;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.navLogo>img {
    height: 36px;
    width: 36px;
}

.guide-btn {
    border: 1px solid #007BFF !important;
    color: #007BFF;
    border-radius: 5px;
    font-weight: 500;
    height: 32px;
    padding: 10px;
    background-color: #fff;
    font-family: 'Poppins'!important;
    font-size: 14px !important;
    gap: 3px;
    
    }
    
.guide-btn:hover{
    color: white !important;
    background-color: #007BFF ;
}



.rightContainer {
    background: url(../../../public/Images/global-bg-rays.svg);
    background-color: #fff;
    background-size: cover;
    overflow: hidden;
    right: 0;
    z-index: 4;
}

.rightContainer {
    bottom: 0;
    left: 480px;
    position: fixed;
    top: 0;
    border-left: 10px solid #e0e0e0;
}

.rcBanner-footer>img {
    height: 25px;
    width: 25px;
    margin-top: 4px;
}

.rcBanner-img-container {
    bottom: 45%;
    position: absolute;
    width: 300px;
}

.rcBanner-img-container>img {
    height: auto;
    width: 180px;
    
}

.flex-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rcBanner-text {
    bottom: 35%;
    font-family: "Poppins";
    font-size: 2em;
    font-weight: 400;
    position: absolute;
    text-transform: uppercase;
}

.rcBanner-text-bold {
    font-weight: 900;
}

.main-area {
    background-color: transparent;
    overflow: hidden;
    width: 100%;
}

.games-section {
    background: #fff;
    border-radius: 0;
    position: relative;
    width: 100%;
    padding: 1rem !important;
}
.custom-game-section{
        background: #fff;
    border-radius: 0;
    position: relative;
    width: 100%;
    padding: 1rem !important;
    padding-top: 120px !important;
}

.p-3 {
    padding: 1rem !important;
}

.games-section-headline {
    color: #959595;
    font-size: .75em;
    font-weight: 400;
    line-height: 18px;
}

.games-window {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gameCard-container {
    /* margin-top: 20px; */
    justify-content: center;
    position: relative;
    width: 46.8%;
   
}

.blink {
    animation: animate 1s linear infinite;
}

@keyframes animate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}



.gameCard {
    background-color: #fff;
    border-radius: 5px;
    display: block;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.gameCard-image {
    border-radius: 5px;
    height: 68.33%;
    width: 100%;
}
.gameCard-image img{
    border-radius: 5px;
}
.gameCard-icon, .gameCard-title {
    display: flex;
    justify-content: center;
}

.gameCard-title {
    border-radius: 0 0 5px 5px;
    border: 1px solid #e0e0e0;
    border-top: none;
    color: #ff05ff;
    font-weight: 700;
    padding: 15px 20px 10px;
    font-family: "Poppins";
    font-size: 20px;


}

.games-section-title {
    color: #2c2c2c;
    font-weight: 600;
    font-family: "Poppins";
    font-size: 18px;
    text-align: center;
   
    
    
}

.rcBanner-footer {
    bottom: 40px;
    color: #2c2c2c;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 32px;
    position: absolute;
    text-align: center;
    font-family: 'Poppins';
}

.icon-text{
    font-size: 9px !important;
    font-weight: 900 !important;
    color: black ;
}


.footer {
    /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */
    border-radius: 0 !important;
    position: relative !important;
    width: 100% !important;
    padding: 4% !important;
    padding-top: 0px !important;

}
.footer div p{
    color: #000;
    letter-spacing: 0.7px;
    font-style: italic !important;
    font-family: "Poppins" !important;
    text-align: center;
    font-size: 15px !important;
    color:#6C757D !important;
}

.footer-divider {
   
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.footer-links>a {
    color: #959595 !important;
    display: flex !important;
    font-size: 1.1em !important;
    font-weight: 400 !important;
    margin-bottom: 10px !important;
}

.footer-text-bold {
    color: #959595;
    font-size: 1em;
    font-weight: 700;
}

.footer-text {
    color: #959595;
    font-size: .9em;
    font-weight: 400;
}

a:hover {
    color: #0056b3 !important;
    text-decoration: underline;
}

a {
    color: #007bff !important;
    text-decoration: none;
    background-color: transparent;
}



.gameCard-container .goverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.collapseCard-container {
    padding: 30px 20px 20px;
    background-color: #fafafa;
}
.collapseCard {
    position: relative;
    width: 100%;
    background-color:"#DC3545 " !important
}
.collapseCard-body {
    padding-left: 55px;
    align-items: center;
    display: flex;
}

.collapseCard-header {
    background-color: #fafafa;
    padding-left: 3px;
    padding-right: 3px;
    top: -13px;
    align-items: center;
    position: absolute;
    display: flex;
    justify-content: center;
}
.collapseCard-title {
    color: #676767;
    font-size: .7em;
    font-weight: 700;
    text-transform: uppercase;
}

.gamecard_bg{
    /* background-image: linear-gradient(to bottom right,red, green,yellow,blue ); */
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
}

.playnow-btn{
    display: inline-block;
    width: 100% !important;
    background-color: #18191d;
    color: #ffffff !important;
    text-decoration: none;
    font-size: 15px !important;
    border-radius: 10px !important;
    position: relative;
    overflow: hidden;
    border: none;
    transition: all 0.4s;
    font-family: "Poppins" !important;
    letter-spacing: 0.5px !important;
    padding: 20px !important;
}

.w-90{
    width: 90% !important;
}
.w-10{
    width: 10%;
}

.icon-head{
    height: 60px;
    border-radius: 50%;
    width: 60px;
    align-items: center;
    color: white;
}



@media screen and (max-width:500px) {
    body{
    overflow: hidden !important;
}
    .height-overflow {
        /* height: 80vh !important; */
        padding-top: 100px !important;
        overflow: hidden !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        position: fixed !important;

    }
    .resposive-height-img{
        /* height: 500px !important; */
        width: 100% !important;
        /* max-width: ; */
    }
}

@media screen and (max-width:768px) {
    .games-section{
        padding: 0.5rem !important;
    }
    .collapseCard-body{
        padding-left: 5px !important;
    }
    .collapseCard-text span{
        font-size: 13px !important;
    }
    .footer div p{
        color: #000;
        letter-spacing: 0.7px;
        font-style: italic !important;
        font-family: "Poppins" !important;
        text-align: center;
        font-size: 13.1px !important;
        color:#6C757D !important;
    }
}

.messagebox-bg{
    background-color: #FFF3CD !important;
    color:#664D03 ;
   
}
.marqueebox-bg{
    background-color: #f7a072!important;
    
   
}

.support-btn{
    font-family: "Poppins" !important;
    font-size: 20px !important;
    background-color: #157347;
    border: none;
    border-radius: 6px;
    padding: 8px;
    width: 100% !important;
    /* width: 430px !important; */
}

@media screen and (max-width:550px) {
    .support-btn{
        /* width: 390px !important; */
    }
}


.custom-game-section{
        background: #fff;
    border-radius: 0;
    position: relative;
    width: 100%;
    padding: 1rem !important;
    padding-top: 120px !important;
}


.height-overflow{
    height: 120vh;
    padding-top: 114px;
    overflow: hidden;
}


.custom-padding{
    padding-top: 100px !important;
}


.landing-header div .w3-teal  {
 top: 38px !important;
}

@media screen and (min-width: 769px) {
    /* STYLES HERE */
    .liveOrCommingSoon {
        bottom: 45px;
        right: 15px;
       }
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) { 
    /* STYLES HERE */
    .liveOrCommingSoon {
        bottom: 45px;
        right: 15px;
       }
}

@media only screen and (max-device-width: 480px) {
    /* STYLES HERE */
    .liveOrCommingSoon {
        bottom: 45px;
        right: 15px;
       }
}

@media only screen and (min-device-width: 1280px) {
.liveOrCommingSoon {
    bottom: 60px;
    right: 15px;
   }
}
