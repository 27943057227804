@media screen and (max-width:550px) {
    body{
      height: 100% !important;
    }
    .custom-form-control {
      width: 295px !important;
    }
    .custom-save-btn {
      width: 66px !important;
    }
 
  }
 
 