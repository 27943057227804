@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,800,900&display=swap"); 
@import url("https://fonts.googleapis.com/css?family=Poppins|Roboto"); 
* { 
    box-sizing: border-box;
} 

div { 
    font-family: Roboto; 
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
} 

.main-area { 
    background-color: transparent; 
    overflow: hidden; 
    width: 100%; 
    overflow-x: hidden; 
    overflow-y: hidden;
} 

nav { 
    display: block;
} 

nav { 
    font-family: Roboto; 
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
} 

.d-flex { 
    display: flex !important;
} 

.justify-content-center { 
    justify-content: center !important;
} 

*,:after,:before { 
    box-sizing: border-box;
} 

:-webkit-scrollbar { 
    display: none;
} 

ul { 
    margin-bottom: 1rem;
} 

ul { 
    margin-top: 0px;
} 

ul { 
    font-family: Roboto; 
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
} 

.MuiPagination_ul { 
    margin: 0; 
    display: flex; 
    padding: 0; 
    flex-wrap: wrap; 
    list-style: none; 
    align-items: center; 
    margin-top: 0px; 
    margin-right: 0px; 
    margin-bottom: 0px; 
    margin-left: 0px; 
    padding-top: 0px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px; 
    list-style-position: initial; 
    list-style-image: initial; 
    list-style-type: none;
} 

li { 
    font-family: Roboto; 
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
} 

button { 
    border-radius: 0; 
    border-top-left-radius: 0px; 
    border-top-right-radius: 0px; 
    border-bottom-right-radius: 0px; 
    border-bottom-left-radius: 0px;
} 

button { 
    margin: 0; 
    font-family: inherit; 
    font-size: inherit; 
    line-height: inherit; 
    margin-top: 0px; 
    margin-right: 0px; 
    margin-bottom: 0px; 
    margin-left: 0px;
} 

button { 
    overflow: visible; 
    overflow-x: visible; 
    overflow-y: visible;
} 

button { 
    text-transform: none;
} 

[type="button"],button { 
    -webkit-appearance: button; 
    appearance: button;
} 

.MuiButtonBase_root { 
    color: inherit; 
    border: 0; 
    cursor: pointer; 
    margin: 0; 
    display: inline-flex; 
    outline: 0; 
    padding: 0; 
    position: relative; 
    align-items: center; 
    user-select: none; 
    border-radius: 0; 
    vertical-align: middle; 
    -moz-appearance: none; 
    justify-content: center; 
    text-decoration: none; 
    background-color: transparent; 
    -webkit-appearance: none; 
    -webkit-tap-highlight-color: transparent; 
    border-top-width: 0px; 
    border-right-width: 0px; 
    border-bottom-width: 0px; 
    border-left-width: 0px; 
    border-top-style: initial; 
    border-right-style: initial; 
    border-bottom-style: initial; 
    border-left-style: initial; 
    border-top-color: initial; 
    border-right-color: initial; 
    border-bottom-color: initial; 
    border-left-color: initial; 
    border-image-source: initial; 
    border-image-slice: initial; 
    border-image-width: initial; 
    border-image-outset: initial; 
    border-image-repeat: initial; 
    margin-top: 10px; 
    margin-right: 0px; 
    margin-bottom: 0px; 
    margin-left: 0px; 
    outline-color: initial; 
    outline-style: initial; 
    outline-width: 0px; 
    padding-top: 10px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px; 
    border-top-left-radius: 0px; 
    border-top-right-radius: 0px; 
    border-bottom-right-radius: 0px; 
    border-bottom-left-radius: 0px; 
    text-decoration-line: none; 
    text-decoration-thickness: initial; 
    text-decoration-style: initial; 
    text-decoration-color: initial; 
    appearance: none;
} 

.MuiPaginationItem_root { 
    color: rgba(0, 0, 0, 0.87); 
    height: 32px; 
    margin: 0 3px; 
    padding: 0 6px; 
    font-size: 0.875rem; 
    min-width: 32px; 
    box-sizing: border-box; 
    text-align: center; 
    font-family: Roboto, Helvetica, Arial, sans-serif; 
    font-weight: 400; 
    line-height: 1.43; 
    border-radius: 16px; 
    letter-spacing: 0.01071em; 
    margin-top: 0px; 
    margin-right: 3px; 
    margin-bottom: 0px; 
    margin-left: 3px; 
    padding-top: 0px; 
    padding-right: 6px; 
    padding-bottom: 0px; 
    padding-left: 6px; 
    border-top-left-radius: 16px; 
    border-top-right-radius: 16px; 
    border-bottom-right-radius: 16px; 
    border-bottom-left-radius: 16px;
} 

.MuiPaginationItem_page { 
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; 
    transition-duration: 250ms, 250ms; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1); 
    transition-delay: 0ms, 0ms; 
    transition-property: color, background-color;
} 

.MuiButtonBase_root.Mui_disabled { 
    cursor: default; 
    pointer-events: none;
} 

.MuiPaginationItem_page.Mui_disabled { 
    opacity: 0.38;
} 

.MuiPaginationItem_page:hover { 
    background-color: rgba(0, 0, 0, 0.04);
} 

[type="button"]:not(:disabled), button:not(:disabled) { 
    cursor: pointer;
} 

.MuiPaginationItem-page.Mui_selected { 
    background-color: rgba(0, 0, 0, 0.08);
} 

.MuiPaginationItem_textSecondary.Mui_selected { 
    color: rgb(255, 255, 255); 
    background-color: rgb(245, 0, 87);
} 

.MuiPaginationItem-page.Mui-selected:hover { 
    background-color: rgba(0, 0, 0, 0.12);
} 

.MuiPaginationItem-textSecondary.Mui_selected:hover { 
    background-color: rgb(197, 17, 98);
} 

svg { 
    vertical-align: middle;
} 

svg { 
    overflow: hidden; 
    overflow-x: hidden; 
    overflow-y: hidden;
} 

.MuiSvgIcon_root { 
    fill: currentcolor; 
    width: 1em; 
    height: 1em; 
    display: inline-block; 
    font-size: 1.5rem; 
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; 
    flex-shrink: 0; 
    user-select: none; 
    transition-duration: 200ms; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); 
    transition-delay: 0ms; 
    transition-property: fill;
} 

.MuiPaginationItem_icon { 
    margin: 0 -8px; 
    font-size: 1.25rem; 
    margin-top: 0px; 
    margin-right: -8px; 
    margin-bottom: 0px; 
    margin-left: -8px;
} 

span { 
    font-family: Roboto; 
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
} 

.MuiTouchRipple_root { 
    top: 0px; 
    left: 0px; 
    right: 0px; 
    bottom: 0px; 
    z-index: 0; 
    overflow: hidden; 
    position: absolute; 
    border-radius: inherit; 
    pointer-events: none; 
    overflow-x: hidden; 
    overflow-y: hidden; 
    border-top-left-radius: inherit; 
    border-top-right-radius: inherit; 
    border-bottom-right-radius: inherit; 
    border-bottom-left-radius: inherit;
} 

/* cards css */

.w-100 { 
    width: 100% !important;
} 

.py-3 { 
    padding-top: 1rem !important;
} 

.py-3 { 
    padding-bottom: 1rem !important;
} 

.list_item { 
    border-bottom: 1px solid #ededed; 
    border-bottom-width: 1px; 
    border-bottom-style: solid; 
    border-bottom-color: rgb(237, 237, 237);
} 

*,:after,:before { 
    box-sizing: border-box;
} 

:-webkit-scrollbar { 
    display: none;
} 

.mx-2 { 
    margin-right: 0.5rem !important;
} 

.mx-2 { 
    margin-left: 0.5rem !important;
} 

.center_xy { 
    align-items: center; 
    display: flex; 
    flex-direction: column;
} 

.list_date { 
    color: rgb(149, 149, 149); 
    font-weight: 500; 
    width: 65px;
} 

.list_divider_y { 
    background-color: rgb(237, 237, 237); 
    height: 30px; 
    width: 1px;
} 

.mx-3 { 
    margin-right: 1rem !important;
} 

.mx-3 { 
    margin-left: 1rem !important;
} 

.list_body { 
    width: 56%; 
    font-size: 0.9em;
} 

small { 
    font-size: 80%;
} 

small { 
    font-size: 80%; 
    font-weight: 400;
} 

.flex_column { 
    flex-direction: column !important;
} 

.font_8 { 
    color: rgb(44, 44, 44); 
    font-weight: 500;
} 

.font_8 { 
    font-size: 0.8em;
} 

.mr-2 { 
    margin-right: 0.5rem !important;
} 

.games_section_headline { 
    color: rgb(149, 149, 149); 
    font-size: 0.75em; 
    font-weight: 400; 
    line-height: 18px;
} 

img { 
    border-style: none; 
    border-top-style: none; 
    border-right-style: none; 
    border-bottom-style: none; 
    border-left-style: none;
} 

img { 
    vertical-align: middle;
} 

img { 
    font-family: Roboto; 
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
} 

b { 
    font-weight: bolder;
} 



/* transcation history css */

.status_badge { 
    font-size: 10px !important; 
    font-weight: 500 !important; 
    opacity: 0.85; 
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px; 
    padding: 3px!important; 
    height: 22px; 
    padding-top: 3px !important; 
    padding-right: 3px !important; 
    padding-bottom: 3px !important;
    background-color: greenyellow !important; 
    padding-left: 3px !important;
} 

.btn_success{
    background-color: #28a745 !important;
}
 .page-link{
    color: white !important;
}

.dateandtime{
    color: #000 !important;
    font-family: "Poppins" !important;
    text-align: left !important;
}

