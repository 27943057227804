#hambergar:hover {
  background-color: white !important;
}
.w3-button {
  /* background-color:blueviolet; */
  color: white;
  /* position:fixed */
  margin: auto;
  right: 0;
  top: 20px;
  /* right:/20px; */
  padding: 0.25rem;
  transition: transform 0.3s ease-in-out;
  /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}
.w3-button.active {
  transform: translateX(-100px);
}

.w3-sidebar {
  width: 95%;
  max-width: 300px;
  height: 100%;
  background-color: #fff;
  color: black !important;
  position: fixed;
  top: 0;
  left: -500px;
  height: 100vh;
  transition: all 0.5s;
  z-index: 99999999 !important;
}
.w3-sidebar-login {
  max-width: 400px;
}
.w3-container {
  padding: 0px;
  top: 0 !important;
  margin: auto;
  background: #fff !important;
}
.w3-teal {
  background: white !important;
}
.w3-bar-block .w3-bar-item {
  background-color: #fff;

  /* padding: 20px; */
  height: 92px;
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  color: #000 !important;
}

/*.w3-bar-block .w3-bar-item:hover{*/
/*    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118));*/
/*    color: black !important;*/
/*}*/

.arrow {
  position: absolute;
  right: 25px;
}
.arrow > img {
  height: 11px;
  width: 6px;
  max-width: 100%;
}

.icon {
  overflow: hidden;
  position: relative;
}
.icon > img {
  height: 34px;
  width: 34px;
  /* max-width: 100%; */
}
#sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: none;
  transition: all 0.5s ease-in-out 0s;
}

/* // side bar css  */

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav a {
  padding: 12px 8px 8px 8px;
  text-decoration: none;
  font-size: 30px;
  /* color: #000000 !important; */
  display: block;
  color: #000 !important;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 300;
}

.sidebar_icon a:hover {
  color: #000 !important;
  transition: none !important;
  text-decoration: none !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 36px;
  margin-left: 50px;
  color: "black";
}
.sidenav .closebtn:hover {
  color: #aa13e1 !important;
  transition: none !important;
  text-decoration: none !important;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

/* .nav-logo{
    margin-right: 190px;
  } */

.canvasheader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: #212529;
  line-height: 0;
}
.canvasheader-login {
  width: 400px !important;
  z-index: 1;
  position: fixed;
  max-width: 95%;
}

.canvas-title {
  color: white;
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  font-family: "Poppins" !important;
  letter-spacing: 0.9px;
}

.fs-icon {
  color: #ccc;
  font-size: 25px;
}
.fs-icon:hover {
  color: #e9e9e9;
}

.hey {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hey-text {
  display: flex;
  align-items: center;
  text-align: left !important;
  font-weight: 900 !important;
  font-size: calc(1.375rem + 1.5vw) !important;
  color: #212529;
  font-family: "Poppins" !important;
}
.hey-text-login {
  font-size: 38px !important;
  font-weight: 900 !important;
}

.hey-all {
  margin-right: 0.25rem;
  margin: 0;
  align-items: center !important;
  display: flex;
  gap: 10px !important;
}

.w3-button:hover {
  background: none !important;
}

.game-name {
  /* padding: 12px 8px 8px 8px; */
  text-decoration: none;
  font-size: 28px !important;
  color: #212529 !important;
  display: block !important;
  color: #212529 !important;
  transition: 0.3s;
  font-family: "Poppins";
}

.collapseCard-text span {
  font-size: 16px !important;
}

.commission-background-login {
  background-color: #dc3545 !important;
}
.commission-background-logout {
  background-color: #dc3545 !important;
}
@media screen and (max-width: 550px) {
  .collapseCard-text span {
    font-size: 14px !important;
    margin-left: 42px !important;
  }
  .w3-teal {
    max-width: 415px !important;
  }
  .w3-bar-block .w3-bar-item {
    height: 75px;
  }
  .game-name {
    font-size: 23px !important;
  }
  .hey-text-login{
    font-size: 28px !important;
    font-weight: 950 !important;
  }

  .canvasheader-login{
    width: 360px !important;
  }

  .w3-sidebar-login{
    max-width: 360px !important;
  }

}
