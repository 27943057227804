.history-tabs{
    color: black !important;
    border: 1px solid #DEE2E6 !important;
    font-weight: 700;
    font-family: "Poppins" !important;
}

.nav-pills .nav-link.active{
   background-color: #0D6EFD !important;
   color: white !important;
    
   
}

.nav-pills .mode.active{
    background-color: #EE414A !important;
    color: white !important;
 }

.history-tab{
    font-size: 11px;
    font-family: "Poppins" !important;
}


.help{
    background-color: #fbd0d5 !important;
    border: 1px solid #faa3ad !important;
    border-radius: 0.375rem !important;
    margin-bottom: 20px !important;
    padding: 1rem !important;
  }

  .help-text{
    color: #003441 !important; 
    /* font-family:" Poppins,sans-serif" !important; */
    line-height: 16px;
    margin-bottom: 0;
    text-align: left;
    letter-spacing: 0px;
font-size: 0.7rem !important;
font-weight: 600;
  }


  .set-input::placeholder{
    font-family: "Poppins" !important;
    font-size: 18px !important;
    font-weight: 500;
  }

  .history-btns{
    font-family: "Poppins" !important;
  }

  

  .history-tabs{
    color: black !important;
    border: 1px solid #DEE2E6 !important;
    font-weight: 700;
}
.nav-pills .nav-link.active{
   background-color: #0D6EFD !important;
   color: white !important;
}
.nav-pills .mode.active{
    background-color: #EE414A !important;
    color: white !important;
 }
.history-tab{
    font-size: 11px;
    font-family: "Poppins" !important;
}
.help{
    background-color: #FBD0D5 !important;
    border: 1px solid #FAA3AD !important;
    border-radius: 0.375rem !important;
    margin-bottom: 20px !important;
    padding: 1rem !important;
  }
  .help-text{
    color: #003441 !important;
    /* font-family:" Poppins,sans-serif" !important; */
    line-height: 16px;
    margin-bottom: 0;
    text-align: left;
    letter-spacing: 0px;
font-size: 0.7rem !important;
font-weight: 600;
  }
  .set-input::placeholder{
    font-family: "Poppins" !important;
    font-size: 18px !important;
    font-weight: 500;
  }
  .history-btns{
    font-family: "Poppins" !important;
  }
  .form-control.add-cash-input{
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius:7px;
    border-bottom-right-radius:7px;
    border: 1px solid #ccc !important;
    padding-top: 21px !important;
    padding-bottom: 21px !important;
}
.input-group-text.icon{
border-top-left-radius:7px ;
border-bottom-left-radius:7px ;
}
.add-cash-input::placeholder{
  font-family: "Poppins" !important;
  font-size: 18px !important;
}
.upi-radio{
  font-size: 1.50rem !important;
  color: #000;
  font-weight: 500;
  font-family: "Poppins" !important;
}
.pay-addcash-btn{
  background-color: #0D6EFD !important;
  color: white !important;
  font-size: 18px !important;
  font-family: "Poppins" !important;
  border-radius: 10px !important;
}
.payments-secured{
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}


.nav-pills .cancel-buttons.active{
  background-color: rgb(9, 97, 204) !important;
}


@media screen and (max-width:550px) {

  .overflow-responsive{
    overflow-x: auto;
    white-space: nowrap;
  }
  .tabs-responsive{
    flex: 0 0 auto;
    width: auto;
    margin-right: 7px;
  }

}
