.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-inner {
  display: flex;
  width: 100%;
}

.carousel-item {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  opacity: 0;
}

.carousel-item.active {
  opacity: 0;
}
.carousel-item:not(.active) {
  opacity: 0;
}

.carousel img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-item.active {
  animation: blurEffect 3s ease forwards;
}

@keyframes blurEffect {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
