@media screen and (max-width:550px) {
   body{
       height: 100% !important;
       overflow-y: auto !important;
   }
   .transition-history-input input::placeholder{
    
    font-size: 16px !important;
}
.transition-history-input {
    padding-top: 15px !important;
}
.withdraw-footerpara{
    font-size: 11px !important;
}
.offcanvas-responsive-height{
    height: 425px !important;
}

}

.transition-history-input input{
    padding: 10px !important;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.transition-history-input input::placeholder{
    font-family: "Poppins" !important;
    font-size: 18px;
}

.transition-history-input button{
    padding: 10px !important;
    padding-inline: 22px !important;
    border-radius: 10px;
    background-color: #6FA1FE;
    color: #fff;
    font-family: "Poppins" !important;
    font-weight: 600;
    border: 1px solid #6FA1FE;
}

.offcanvas-body{
    padding: 0 !important;

}

.offcanvas{
    padding: 0px 12px;
}

.eye-btn{
    font-size: 8px !important;
    padding: 6px 8px !important;
}
.eye-btn-img{
    width:1.2em !important;
    height:1.2em !important;
}