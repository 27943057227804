/* YourPaginationStyles.css */

/* Container */
.custom-pagination-container {
    border: 1px solid #61A1FE;
    border-radius: 6px !important;
    background-color: #61A1FE;
  }
  
  /* Page item */
  .custom-page-item {
    /* Your styles here */
}
  
  /* Page link */
  .custom-page-link {
    color: white !important;
    font-size: 18px;
  }
  
  /* Previous item */
  .custom-previous-item {
    width: 100%;
  }
  
  /* Previous link */
  .custom-previous-link {
    color: #61A1FE ;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px !important;

  }
  
  /* Next item */
  .custom-next-item {
    width: 100%;
  }
  
  /* Next link */
  .custom-next-link {
    color: #61A1FE ;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px !important;

  }
  
  /* Break item */
  .custom-break-item {
    
  }
  
  /* Break link */
  .custom-break-link {
    
  }
  
  /* Active item */
  .custom-active {
    
  }
  

  @media screen and (max-width:550px) {
    body{
        height: 100% !important;
        overflow-y: auto !important;
    }
    
}