.ludo-popular-card-container {
  width: 96%;
  height: 250px !important;
  max-height: 250px !important;
  margin: 0 auto;
  /* border-radius: 10px; */
  /* background: linear-gradient(to bottom, #03045e, #212263); */
  background: linear-gradient(to bottom, #8105ed, #7805dd);
  display: flex;
  gap: 4px;
  position: relative;
  overflow: hidden;
}

.ludo-popular-top-status {
  position: absolute;
  top: 10px;
  left: 0px;
  /* background: #e9972b; */
  /* background: linear-gradient(to bottom, #f1f0ef, #272864); */
  background: linear-gradient(to bottom, #f1f0ef, #410378);
  color: black;
  font-size: 15px;
  font-weight: 500;
  padding: 3px 6px;
  z-index: 1;
  /* border-radius: 0px 14px 14px 0px; */
  /* width: 100%; */
  text-align: center;
}

.ludo-popular-card-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid green; */
  width: 100%;
  height: 250px !important;
  max-height: 250px !important;
  position: relative;
}

.ludo-popular-card-img {
  width: 100px;
  position: absolute;
  right: -20px;
  bottom: -30px;
  /* display: none; */
  opacity: 20%;
}

.ludo-popular-card-middle-img {
  width: 55%;
  transform: rotate(30deg);
  z-index: 2;
  /* border: 2px solid red; */
}

.ludo-popular-card-background-img {
  position: absolute;
  width: 80%;
  opacity: 50%;
}

.ludo-popular-card-text {
  /* border: 2px solid yellow; */
  /* color: #e9972b; */
  color: white;

  font-size: 26px;
  /* font-family: monospace; */
  font-weight: bold;
  z-index: 1;
  font-style: italic;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  /* border: 2px solid red; */
  text-align: center;
  line-height: 1;
  position: absolute;
  bottom: 20px;
  padding: 8px 10px;
  border-radius: 10px;
  /* background: linear-gradient(to bottom, #03045e, #9697b0); */
  background: linear-gradient(to bottom, #410378, #9697b0);
  z-index: 3;
}

.test-class {
  height: 100%;
  width: px;
  background-color: green;
}

@media (max-width: 390px) {
  .ludo-popular-card-text {
    font-size: 18px;
  }
  .ludo-popular-card-middle-img {
    width: 75%;
  }
  .ludo-popular-card-background-img {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .ludo-popular-card-text {
    font-size: 15px;
  }
  .ludo-popular-card-middle-img {
    width: 85%;
  }
  .ludo-popular-card-background-img {
    width: 100%;
  }
}

@media (max-width: 290px) {
  .ludo-popular-top-status {
    font-size: 14px;
  }
  .ludo-popular-card-text {
    font-size: 14px;
  }
  .ludo-popular-card-middle-img {
    width: 90%;
  }
  .ludo-popular-card-background-img {
    width: 110%;
  }
}

@media (max-width: 270px) {
  .ludo-popular-top-status {
    font-size: 12px;
  }
  .ludo-popular-card-text {
    font-size: 12px;
  }
  .ludo-popular-card-middle-img {
    width: 90%;
  }
  .ludo-popular-card-background-img {
    width: 110%;
  }
}
