.main-area {
    background-color: transparent;
    overflow: hidden;
    width: 100%;
}

.splash-overlay {
    background: linear-gradient(180deg, transparent -315px, #000 283.5px);
    height: 100vh;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.splash-screen {
    -webkit-animation: splashAnim 22s linear infinite;
    animation: splashAnim 22s linear infinite;
    height: 100%;
}

.center-xy {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.login-footer {
    padding: 0 10px;
    bottom: 5%;
    color: #676767;
    font-size: .75em;
    font-weight: 400;
    line-height: 15px;
    /* position: relative; */
    text-align: center;
    max-width: 480px;
    width: 100%;
    z-index: 3;
    /* padding-top: 33%; */
}
@media screen and (max-width: 480px) {
    .login-footer {
        /* padding-top: 60%; */
    }
}
    

element.style {
    top: 25%;
    z-index: 3;
}

.splash-screen {
    -webkit-animation: splashAnim 22s linear infinite;
    animation: splashAnim 22s linear infinite;
    height: 100%;
}

figure {
    margin: 0 0 1rem;
}

.font-15 {
    color: #2c2c2c;
    font-size: 1.5em;
    font-weight: 700;
}

.text-white {
    color: #fff !important;
}

.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}

element.style {
    width: 85%;
    height: 70px;
    border-radius: 5px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

element.style {
    width: 100px;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.hidden {
    display: none !important;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}

.refer-button {
    border: none;
    border-radius: 5px;
    font-size: 1em;
    font-weight: 700;
    height: 48px;
    padding: 0 22px;
    color: #fff;
    text-transform: uppercase;
}

.bg-green {
    background-color: #0db25b;
}

.mt-4, .my-4 {
    margin-top: 1.5rem !important;
}


.register-agree{
    padding-top: 10px !important;
    font-size: 0.9rem !important;
    letter-spacing: 0.5px !important;
   
    font-family:"Poppins" !important;
}

.register-text{
    font-size: 15px !important;
}