/* BottomNavBar.css */
.bottom-nav-bar {
  display: none; /* Hide the navigation bar by default */
}

@media only screen and (max-width: 768px) {
  .bottom-nav-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* background-color: #dc3545; */
    background-color: #03045e;
    /* background-color: #f7a172; */
    border-top: 1px solid #ddd; /* Add a border on top for separation */
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.nav-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #555; /* Adjust the color of the icons and text */
  padding: 12px;
  transition: color 0.3s ease;
}

.nav-items:hover {
  color: #000; /* Change color on hover */
}

.nav-items svg {
  width: 22px; /* Adjust the size of the SVG icons */
  height: 22px;
}
