.ludo-classic-card-container {
  /* color: green; */
  /* border: 2px solid #ffd672; */
  width: 100%;
  height: 160px;
  margin: 0 auto;
  /* border-radius: 10px; */
  /* blue */
  /* background-image: url("../../assets/images/bg_img/1.jpg");
  background-size: cover; */

  /* black */
  /* background-image: url("../../assets/images/bg_img/4.jfif"); */
  /* background-size: cover;

  background-position-x: 180px;
  background-position-y: 20px; */
  /* background: rgba(14, 71, 161, 0.9); */
  /* background: #171717; */

  /* new background */
  /* background-image: url("../../assets/images/bg_img/download.jfif");
  background-size: cover;
  background-position-x: -50px;
  background-position-y: 0px; */

  /* liner gradient  */
  background: linear-gradient(to bottom, #03045e, #212263);
  /* background: linear-gradient(to bottom, #24258c, #070954); */

  display: flex;
  gap: 4px;
  /* padding: 4px; */
  position: relative;
}

.ludo-classic-card-leftside {
  /* border: 2px solid green; */
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.ludo-classic-card-leftside > .top-status {
  position: absolute;
  top: 8px;
  left: 0px;
  /* background-color: rgba(243, 159, 49, 1); */
  color: black;
  font-size: 13px;
  /* padding: 2px 15px 2px 10px; */
  border-radius: 0px 4px 4px 0px;
}

.ludo-classic-card-leftside .card-img {
  width: 100%;
  margin-left: 10px;
  /* border-radius: 10%; */
  margin: 0 auto;
  position: absolute !important;
  bottom: 0px !important;
  left: 0px !important;
  border-radius: 0px 0px 0px 10px;
  z-index: 1;
}

.ludo-classic-card-rightside {
  /* border: 2px solid green; */
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* gap: 8px; */
}

.ludo-classic-card-rightside .game-title {
  font-size: 28px;
  color: white;
  font-family: monospace;
  font-style: italic;
  word-spacing: -4px;
  font-weight: bold;
  color: #e3b751;
  line-height: 1;
  /*  */
}

.ludo-classic-card-rightside .play-btn {
  width: fit-content;
  padding: 8px 10px;
  /* border: 2px solid #9c641b; */
  border: none;
  background: #e9972b;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px !important;
  z-index: 2;
}

@media (max-width: 390px) {
  .ludo-classic-card-leftside {
    width: 40%;
  }
  .ludo-classic-card-rightside {
    width: 60%;
  }
  .ludo-classic-card-rightside .game-title {
    font-size: 24px;
  }
  .ludo-classic-card-leftside .card-img {
    width: 110%;
  }
}

@media (max-width: 320px) {
  .ludo-classic-card-rightside .game-title {
    font-size: 21px;
  }
  .ludo-classic-card-leftside .card-img {
    width: 110%;
  }
}

@media (max-width: 290px) {
  .ludo-classic-card-rightside .game-title {
    font-size: 20px;
  }
  .ludo-classic-card-leftside .card-img {
    width: 110%;
  }
}

@media (max-width: 270px) {
  .ludo-classic-card-rightside .game-title {
    font-size: 18px;
  }
}
