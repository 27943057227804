.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    right: 4%;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 13px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #000;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .loaderReturn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgb(255, 255, 255);
  }
  

  .qr-code{
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    padding: 0.5rem 1.5rem 0.5rem 0px !important;
    border-radius: 0.25rem !important;
    vertical-align: middle !important;
    margin-bottom: 0.75rem !important;
    margin-top: 10px !important;
    cursor: pointer !important;
    text-align: center !important;

}


.qr-image-add{
  width: 55px;
  height: 55px;
  background: rgb(240, 240, 240);
  border: 2px solid rgba(17, 56, 91, 0.8);
  border-radius: 6px;
  margin-bottom: 0.75rem;
  text-align: center;
  margin-left: 1.5rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.qr-code-text{
  font-weight: 400;
    margin-left: 1.2rem;
    color: #11385b !important;
    font-family: "Poppins" !important;
    font-size: 18px;
}


.qr-cheakbox{
  color: #11385b;
  font-family: "Poppins" !important; 
  font-size: 18px;
}
.pay-btn-addcase{
  box-sizing: border-box;
  appearance: none;
  border: none;
  width: 100%;
  padding: 1.25rem 1.5rem;
  outline: none;
  margin-top: 1.2rem;
  text-align: center;
  box-shadow: none;
  opacity: 1;
  font-family:" Lato, sans-serif" !important;
  color: rgb(255, 255, 255);
  background: rgb(17, 56, 91);
  font-style: normal;
  font-variant: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 1.25em;
  font-weight: 700;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  left: 0px;
  bottom: 0px;
  border-radius: 0px;
  visibility: visible;
  cursor: pointer !important;
}

.pay-btn-addcase:disabled {
  background-color: #70889D; 
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 120px;
  height: 20px;
  border-radius: 20px;
  background:
   repeating-linear-gradient(135deg,#f03355 0 10px,#ffa516 0 20px) 0/0%   no-repeat,
   repeating-linear-gradient(135deg,#ddd    0 10px,#eee    0 20px) 0/100%;
  animation: l3 2s infinite;
}
@keyframes l3 {
    100% {background-size:100%}
}