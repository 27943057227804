.kyc-input{
background: #fafafa;
border: 1px solid #e0e0e0;
border-radius: 5px;
color: #2c2c2c;
font-size: 1em;
font-weight: 500;
height: 48px;
position: relative;
text-transform: uppercase;
display: flex;
align-items: center;
padding-left: 15px;
width: 100%;
}
.refer-footer{
background: #fafafa;
border: 1px solid #e0e0e0;
border-radius: 5px;
color: #2c2c2c;
font-size: 1em;
font-weight: 500;
height: 48px;
position: relative;
text-transform: uppercase;
display: flex;
align-items: center;
padding-left: 15px;
}

.refer-button {
    border: none;
    border-radius: 5px;
    font-size: 1em;
    font-weight: 700;
    height: 48px;
    padding: 0 22px;
    color: #fff;
    background: #6c757d !important;
    text-transform: uppercase;
}
.kyc-select {
    bottom: 0;
    left: 0;
    max-width: 480px;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 4;
}

.kyc-select-enter-done{
display: block !important;
-webkit-transform: translateZ(0)!important;
transform: translateZ(0)!important;
}


.kyc-select .box {
    display: none;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 -3px 8px 0 rgb(0 0 0 / 12%);
    max-height: 88%;
    overflow-y: scroll;
    pointer-events: auto;
    width: 100%;
    transition: transform .2s cubic-bezier(0,0,.3,1) 0s;
    /* will-change: transform;
    transition: -webkit-transform .2s cubic-bezier(0,0,.3,1) 0s;
    -webkit-transform: translate3d(0,360px,0);
    transform: translate3d(0,360px,0); */
}

.kyc-select .header {
    border-bottom: 1px solid #e0e0e0;
    z-index: 3;
    border-radius: 10px 10px 0 0;
    position: fixed;
    max-width: 480px;
    width: 100%;
    padding: 30px 20px;
}

.kyc-select .option {
    border-bottom: 1px solid #e0e0e0;
    color: #959595;
    font-size: 1em;
    font-weight: 500;
    height: 57px;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
    padding-left: 20px;
}

a{
    color: #fff !important;
    text-decoration: none;
}
a:hover{
    color: #fff !important;
    text-decoration: none !important;

}

/* kyc2 page */

.kyc-doc-input{
    border-bottom: 1px solid #ffcc5b;
    position: relative;
    width: 100%;
}
.kyc-doc-input input {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: -2px;
    padding-left: 0;
    text-transform: uppercase;
    width: 100%;
}

.kyc-doc-input .label {
    font-size: .8em;
    font-weight: 500;
    position: relative;
    color: #959595;
    transition: all .3s;
}

/* kyc3 */

.mytext {
    color: #2c2c2c;
    font-size: .95em !important;
    font-weight: 400;
    line-height: 21px;
    width: 100%;
}

.doc-upload {
    align-items: center;
    background-color: #fafafa;
    border-radius: 5px;
    border: 1px dashed #e0e0e0;
    display: flex;
    height: 70px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.doc-upload input {
    height: 100%;
    opacity: 0;
    width: 100%;
    z-index: 2;
}

.kyc-select .overlay {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background-color: #2c2c2c;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: opacity .2s;
}

.kyc-select .box {
    display: none;
    border-radius: 16px 16px 0 0 !important;
    box-shadow: 0 -3px 8px 0 rgb(0 0 0 / 12%);
    max-height: 88%;
    overflow-y: scroll !important;
    pointer-events: auto !important;
    width: 100%;
    will-change: transform;
    transition: -webkit-transform .2s cubic-bezier(0,0,.3,1) 0s;
    transition: transform .2s cubic-bezier(0,0,.3,1) 0s;
    transition: transform .2s cubic-bezier(0,0,.3,1) 0s,-webkit-transform .2s cubic-bezier(0,0,.3,1) 0s;
    -webkit-transform: translate3d(0,360px,0);
    transform: translate3d(0,360px,0);
}

.kyc-select .header {
    border-bottom: 1px solid #e0e0e0;
    z-index: 3;
    border-radius: 10px 10px 0 0;
    position: fixed;
    max-width: 480px;
    width: 100%;
    padding: 30px 20px;
}

.kyc-doc-input {
    border-bottom: 1px solid #ffcc5b;
    position: relative;
    width: 100%;
}

.kyc-doc-input input {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: -2px;
    padding-left: 0;
    text-transform: uppercase;
    width: 100%;
}


.kyc-status{
    color: #842029;
    font-family: "Poppins" !important;
    margin-left: 10px;
    font-weight: 900 !important;
    font-size: 16px;
}

.kyc-btn{
    background-color: #DC3545 ;
    color: white;
    border: none;
    font-family: "Poppins" !important;
    font-size: 13px !important;
    padding: 6px;
    border-radius: 4px;
    margin-right: 10px !important;
}
.kyc-btn:hover{
    background-color: #842029 ;
}
.kyc-btn-verified{
    background-color: #16C60C;
    color: white;
    border: none;
    font-family: "Poppins" !important;
    font-size: 15px !important;
    padding: 6px;
    border-radius: 4px;
    margin-right: 10px !important;
    border: 1px solid #16C60C;
}

/* .kyc-btn-verified:hover{
    background-color: #507210 ;
    color: white;
} */


.kyc-btn-profile{
    background-color: white ;
    color: #DC3545;
    border: 1px solid #DC3545 ;
    font-family: "Poppins" !important;
    font-size: 15px !important;
    padding: 6px;
    border-radius: 4px;
    margin-right: 10px !important;
}

.kyc-btn-profile:hover{
    background-color: rgba(255, 0, 0, 0.695) ;
    color: white;
}

.kyc-status-profile{
    color: black;
    font-family: "Poppins" !important;
    margin-left: 10px;
    font-weight: 900 !important;
    font-size: 16px;
}
.kyc-status-profiles{
    color: black;
    font-family: "Poppins" !important;
    /* margin-left: 10px; */
   
}


.aadharcard::placeholder{
    color: #ccc !important;
    font-size: 1.1rem !important;
    font-weight: 400;
    font-family: "Poppins" !important;
}