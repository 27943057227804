.ludo-quick-card-container {
  width: 96%;
  height: 250px;
  margin: 0 auto;
  /* border-radius: 10px; */
  /* background: linear-gradient(to bottom, #03045e, #212263); */
  /* background: linear-gradient(to bottom, #d0a405, #fdc500); */
  background: linear-gradient(to bottom, #0cc4dc, #08a3b7);
  /* background: linear-gradient(to bottom, #21a50d, #1b860a); */
  display: flex;
  gap: 4px;
  position: relative;
  overflow: hidden;
}

.ludo-quick-top-status {
  position: absolute;
  top: 10px;
  left: 0px;

  background: #e9972b;
  /* background: linear-gradient(to bottom, #f1f0ef, #a58308); */
  background: linear-gradient(to bottom, #f1f0ef, #057e8e);
  /* background: linear-gradient(to bottom, #f1f0ef, #105106); */
  color: black;
  font-size: 15px;
  font-weight: 500;
  padding: 3px 6px;
  z-index: 1;

  /* width: 100%; */
  text-align: center;
}

.ludo-quick-card-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid green; */
  width: 100%;
  height: 100%;
  position: relative;
}

.ludo-quick-card-img {
  width: 50%;
  position: absolute;
  right: -20px;
  bottom: -30px;
  /* display: none; */
  opacity: 40%;
}

.ludo-quick-card-middle-img {
  width: 100%;
  transform: rotate(10deg);
  z-index: 2;
  /* border: 2px solid red; */
}

.ludo-quick-card-background-img {
  position: absolute;
  width: 80%;
  opacity: 80%;
}

.ludo-quick-card-text {
  /* border: 2px solid yellow; */
  /* color: #e9972b; */
  color: white;
  font-size: 26px;
  /* font-family: monospace; */
  font-weight: bold;
  z-index: 1;
  font-style: italic;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  text-align: center;
  line-height: 1;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 20px;
  padding: 8px 10px;
  border-radius: 10px;
  /* background: linear-gradient(to bottom, #03045e, #9697b0); */
  /* background: linear-gradient(to bottom, #a58308, #9697b0); */
  background: linear-gradient(to bottom, #057e8e, #9697b0);
  /* background: linear-gradient(to bottom, #0d4105, #75d666); */
}

/* @media (max-width: 390px) {
  .ludo-quick-card-text {
    font-size: 18px;
  }
  .ludo-quick-card-middle-img {
    width: 135%;
  }
  .ludo-quick-card-background-img {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .ludo-quick-card-text {
    font-size: 15px;
  }
  .ludo-quick-card-middle-img {
    width: 160%;
  }
  .ludo-quick-card-background-img {
    width: 100%;
  }
}

@media (max-width: 290px) {
  .ludo-quick-top-status {
    font-size: 14px;
  }
  .ludo-quick-card-text {
    font-size: 14px;
  }
  .ludo-quick-card-middle-img {
    width: 170%;
  }
  .ludo-quick-card-background-img {
    width: 110%;
  }
}

@media (max-width: 270px) {
  .ludo-quick-top-status {
    font-size: 12px;
  }
  .ludo-quick-card-text {
    font-size: 12px;
  }
  .ludo-quick-card-middle-img {
    width: 175%;
  }
  .ludo-quick-card-background-img {
    width: 110%;
  }
} */
